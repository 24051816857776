// Variables Import
@import "./Sass/colors";

@import "./Sass/fonts";

@import "./Sass/mixins";

@import "./Sass/typography";

@import "./Sass/forms";

@import "./Sass/buttons";

@import "./Sass/modals";

@import "./Sass/table";

/*==========================
    Wobot Dashboard Style
============================*/
* {
  box-sizing: border-box;
  -webkit-box-sizing: border-box;
}

body {
  font-family: $secondary-font;
  margin: 0px;
  padding: 0px;
  font-size: 14px;
  line-height: normal;
  font-weight: 400;
  color: $text-primary;
  @include set-transition(ease-in 0.5s);
  overflow-x: hidden;
}

a {
  @include set-transition();
  text-decoration: none;
  color: $primary-color;

  &:hover,
  &:focus {
    text-decoration: none;
    outline: none;
    color: $text-primary;
  }

  &.active_link {
    background-color: $primary-color;
    color: $pure-white;
  }
}

ul {
  list-style: none;
  padding: 0;

  li {
    position: relative;
    list-style: none;
    padding-left: 0px;
  }
}

blockquote {
  background: $pure-white;
  position: relative;
  padding: 30px;
  margin: 30px auto 30px;
  border-radius: 7px;

  &p {
    font-size: 16px;
    margin: 0 auto 10px;
    line-height: 24px;
  }

  &.quote_owner {
    color: $text-secondary;
    font-weight: 500;
    font-size: 12px;
    display: block;
    font-style: italic;
    line-height: 34px;
  }
}

img {
  max-width: 100%;
  width: auto;
  height: auto;
}
.p-relative {
  position: relative;
  .inline-block{
  position: absolute;
  top: 0;
  right: 4px;
  }
}

.block-wrapper{
  padding: 15px 20px;
  border-radius: 16px;
  word-break: break-all;
  overflow: hidden;
  margin-bottom: 20px;
  .title{
    position: relative;
    .status{
        position: absolute;
        top: 0px;
        right: 0;
    }
  }
    .details{
      display: inline-flex;
      .label{
        margin-bottom: 0px;
        margin-right: 30px;
          li{
            margin-bottom: 8px;
          }
      }
      .data{
        margin-bottom: 0px;
        li{
          margin-bottom: 8px;
        }
      }
  }
  .conn-details{
    display: flex;
    justify-content: space-between;
    li{
        span{
            display: block;
            word-break: break-all;
            svg{
                cursor: pointer;
            }
        }
    }
    .link{
      max-width: 180px;
      word-break: break-all;
    }
  }
  // .details{
  // ul{
  //     li{
  //         display: flex;
  //         justify-content: space-between;
  //         margin-bottom: 15px;
  //         .tag{
  //               align-items: center;
  //               cursor: default;
  //               display: inline-flex;
  //               line-height: 20px;
  //               max-width: 100%;
  //               outline: none;
  //               overflow: hidden;
  //               position: relative;
  //               text-decoration: none;
  //               vertical-align: middle;
  //               white-space: nowrap;
  //           }
  //       }
  //   }
  // }
  .title-block{
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
  }
  .content-block{
    display: flex;
    justify-content: space-between;
    .content{
      overflow: hidden;
      margin-right: 20px;
      .link{
        word-break: break-all;
      }
    }
    .thumb{
      width: 300px;
      img{
        border-radius: 4px;
      }
    }
  }
}


/*================================
      GENERAL CODE
  ==================================*/

// Panel
.wobot-dashboard {
  position: relative;
  z-index: 1;
  width: 100%;
}

.wobot-color-bg {
  background-color: $pure-white;
  min-height: 100vh;
  width: 100%;
}

.curser-p{
  cursor: pointer;
}

.border-circle{
  border-radius: 50px;
}


.pwd{
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

/*================================
      TOP HEADER CODE
  ==================================*/
.dropdown-item {
  &.active,
  &:active {
    color: $pure-white;
    text-decoration: none;
    background-color: transparent;
  }
}

/* General Code */

.hidden-block {
  visibility: hidden;
  display: none;
}

.p-25 {
  padding: 25px;
}

.mt-50 {
  margin-top: 50px;
}

.mb-50 {
  margin-bottom: 50px;
}

.dropdown .bxsw-none {
  box-shadow: none;
}

.full-height {
  height: 100vh;
}

.full-width{
  width: 100vh;
}

.full-h{
  height: 100%;
}

.full-w{
  width: 100%;
}

.full-panel{
  height: calc( 100% - 0rem );
}

.float-none{
  float: none;
}

// Tags
.tag-block{
  border-radius: 4px;
  width: fit-content;
  height: auto;
  display: inline-block;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: .25rem;
  margin-right: 5px;
}
.tag-default{
  background-color: $tag-default-bg;
  color: $primary-color;
}
.tag-warning{
  background-color: $tag-warning-bg;
  color: $red-failure;
}
.tag-success{
  background-color: $tag-success-bg;
  color: $green-success;
}
.tag-orange{
  background-color: $tag-orange-bg;
  color: $brand-orange;
}
.tag-lg{
  padding: 6px 16px;
}
.tag-md{
  padding: .25em .4em;
}



/*================================
      SIDEBAR
  ==================================*/
.navbar {
  & .aside-toggler {
    padding: 0.25rem 0.75rem;
    font-size: 1.25rem;
    line-height: 1;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    cursor: pointer;

    .toggler-bar {
      height: 2px;
      width: 20px;
      margin-bottom: 4px;
      @include set-transition(all 0.3s);
      display: block;
      background: $pure-black;
      border-radius: 50px;
    }

    & .toggler-bar:nth-child(2) {
      width: 15px;
    }

    & .toggler-bar:nth-child(3) {
      width: 10px;
    }

    &:hover .toggler-bar:nth-child(2),
    &:hover .toggler-bar:nth-child(3) {
      width: 20px;
    }
  }
}

.mobile-btn {
  display: none;
  width: 50px;
  height: 50px;
  background-color: #2b5bde;
  line-height: 3.5;
  text-align: center;
  margin: 0 auto;
  border-radius: 50px;
}

a[aria-expanded="true"] {
  background: #2b5bde;
}

a[data-toggle="collapse"] {
  position: relative;
}

.aside-scrollable {
  position: relative;
  overflow-y: auto;
}

/*================================
     Dashboard Main Panel(BODY)
  ==================================*/
#content {
  width: calc(100% - 235px);
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  overflow: auto;
  background: $pure-white;
  @include set-transition(all 0.5s);

  &.active {
    width: calc(100% - 0px);
    @include set-transition(all 0.5s);
  }
}

#content_process {
  width: calc(100% - 325px);
  min-height: 100vh;
  position: absolute;
  top: 0;
  right: 0;
  overflow: auto;
  background: $pure-white;
  @include set-transition(all 0.3s);

  &.active {
    width: calc(100% - 0px);
    @include set-transition(all 0.3s);
  }
}

.main-body-wrapper {
  padding: 30px;
  position: relative;
  z-index: 0;

  & .wobot-panel {
    position: relative;
    margin: 0 auto;
    background: $form-bg-color;
    border: 1px solid transparent;

    .card-body {
      padding: 30px;

      .panel-title {
        .title {
          text-align: center;
          font-size: 34px;
        }
      }

      .panel-body {
        .panel-group {
          @include set-flex(space-between, center, unset);
          margin-bottom: 30px;

          .title {
            font-size: 24px;
            color: $text-highlight;
          }
        }
      }
    }
  }

  .header-primary {
    margin-bottom: 0;
  }


  .bottom-action-block {
    @include set-flex(flex-end, center, unset);

    .action-next {
      button,
      a {
        margin-left: 10px;

        &:first-child {
          margin-left: 0px;
        }
      }
    }
  }
}

.toggle-usecase-body {
  .form-group {
    & div {
      label {
        font-weight: 400;
      }
    }
  }
}

.footer-text p {
  font-size: 12px;
}

.contnet-404 {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
}

// .dropdown-toggle::after {
//   display: block;
//   position: absolute;
//   top: 50%;
//   right: 20px;
//   transform: translateY(-50%);
// }

// TOST POPUP
.Toastify {
  .Toastify__toast-container {
    color: $text-primary;
    .Toastify__toast--info {
      background: $pure-white;
      border-left: 4px solid $primary-color;
    }
    .Toastify__toast--error{
      background: $pure-white;
      border-left: 4px solid $red-failure;
    }
    .Toastify__toast--warning{
      background: $pure-white;
      border-left: 4px solid $brand-orange;
    }

    .Toastify__toast {
      border-radius: 5px;
      .Toastify__toast-body {
        margin: 10px 25px 10px 10px;
        word-break: break-all;
        align-self: center;
        
      }

      .Toastify__close-button {
        position: absolute;
        right: 15px;
        top: 0;
        bottom: 0;
        outline: 0px;
        svg{
          path{
            fill: $text-tertiary;
          }
        }
      }
    }
  }
}

// Panel-Bar
.panel-bar {
  background-color: $form-bg-color;
  border-radius: 6px;
  padding: 10px 15px;
  @include set-flex(space-between, center, center);
  .title {
    margin-bottom: 0px;
  }
  .meta-data {
    ul {
      @include set-flex(none, center, center);
      margin-bottom: 0;
      li {
        &:after {
          content: "";
          width: 2px;
          height: 10px;
          display: inline-block;
          position: relative;
          background-color: $text-tertiary;
          margin: 0 8px;
        }
        &:last-child::after {
          content: none;
          display: none;
        }
      }
    }
  }
}

//Filter-bar
.filter-bar {
  background-color: $form-bg-color;
  border-radius: 12px;
  padding: 24px;
}

.rdtPicker {
  left: -50px;
  border-radius: 6px;
  top: 45px;
  &td.rdtDay:hover,
  &td.rdtHour:hover,
  &td.rdtMinute:hover,
  &td.rdtSecond:hover,
  &.rdtTimeToggle:hover {
    background: #eff3fd;
  }
}

// Active and Inactive
.status-block{
  width: 5px;
  height: 5px;
  display: inline-block;
  border-radius: 50px;
  margin-right: 5px;
}

.active-status{
    background-color: $green-success;
}
.inactive-status{
  background-color: $red-failure;
}



/* Modal Sedule Time */
.week-days-selector {
  display: flex;
  align-items: center;
  align-self: center;
}

.roi-wrap {
  display: flex;
  align-items: baseline;
  align-self: center;
  justify-content: space-between;
}

.week-days-selector p {
  margin-right: 15px;
  margin-bottom: 0px;
}

.week-days-selector input {
  display: none !important;
}

.week-days-selector input[type="checkbox"] + label {
  display: inline-block;
  border-radius: 0px;
  background: $primary-extra-light-focused;
  height: 40px;
  width: 40px;
  border-radius: 50px;
  margin-right: 3px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 0px;
}

.week-days-selector input[type="checkbox"]:checked + label {
  background: #3766e8;
  color: #ffffff;
}

.radio {
  margin: 0.5rem 0;
  display: flex;
  align-items: center;
  align-self: center;
}

.radio input[type="radio"] {
  position: absolute;
  opacity: 0;
}

.radio input[type="radio"] + .radio-label:before {
  content: "";
  background: #ffffff;
  border-radius: 100%;
  border: 1px solid #3766e8;
  display: inline-block;
  width: 16px;
  height: 16px;
  position: relative;
  margin-right: 0.5rem;
  vertical-align: top;
  cursor: pointer;
  text-align: center;
  -webkit-transition: all 250ms ease;
  transition: all 250ms ease;
}

.radio input[type="radio"]:checked + .radio-label:before {
  background-color: #3766e8;
  box-shadow: inset 0 0 0 4px #f4f4f4;
}

.radio input[type="radio"]:focus + .radio-label:before {
  outline: none;
  border-color: #3766e8;
}

.radio input[type="radio"]:disabled + .radio-label:before {
  box-shadow: inset 0 0 0 4px #f4f4f4;
  border-color: #b4b4b4;
  background: #b4b4b4;
}

.radio input[type="radio"] + .radio-label:empty:before {
  margin-right: 0;
}

.roi-action li a {
  color: #000;
}

.roi-action li::after {
  content: "|";
  display: block;
  width: 10px;
  height: 10px;
  position: absolute;
  right: -15px;
  top: 0;
  color: #bed8d4;
}

.roi-action li:last-child::after {
  display: none;
}

.toggler .add-roi {
  cursor: pointer;
}

.contnet-404 {
  max-width: 550px;
  margin: 0 auto;
  text-align: center;
}

.title-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 30px;
}

.mobile-select{
    display: none;
}


/*================================
      MEDIA QUEERY
  ==================================*/
/* Screen Size - 991px */
@media (max-width: 991px) {
    #content {
    width: 100%;

    &.active {
      width: 100%;
    }
  }
  .login-bg {
    display: none !important;
  }

   .mobile-select {
    display: block;
    margin-bottom: 30px;
  }

  .header {
    .dropdown {
      button {
        .userdropdown {
          display: none;
        }
      }
    }
  }
}

/* Screen Size - 766px */
@media (max-width: 768px) {
  .main-body-wrapper {
    .wobot-panel {
      .card-body {
        padding: 20px;
      }
    }

    .search-filter {
      width: 100%;
      float: none;
    }
  }
  .action-select{
    width: 100%;
    margin-top: 20px;
  }
  .block-wrapper{
    .conn-details{
      display: block;
        li{
          margin-bottom: 20px;
        }
    }
  .content-block{
    .content{
      max-width: 100%;
      .link{
        display: none;
      }
    }
    .thumb{
      max-width: 120px;
      img{
        width: 100%;
      }
    }
  }
  .inner-meta-data{
    ul{
      li{
        display: grid;
          span{
            margin-top: 10px;
          }
        }
      }
    }
  }
  .full-panel {
    height: auto;
  }
}



/* Screen Size - 592px */
@media (max-width: 592px) {
  .main-body-wrapper {
    padding: 15px;

    & .search-filter {
      input {
        width: 100%;
        margin-top: 20px;
      }
    }
  }
}

/* Screen Size - 425px */
@media (max-width: 425px) {
  .wobot-login-wrapper {
    .wobot-login-block {
      width: 100%;
    }
  }
}

/* Screen Size - 375px */
@media (max-width: 375px) {
  .wobot-login-block {
    width: 100%;
  }
  .panel-bar {
    display: block;
    .title {
      margin-bottom: 20px;
    }
  }
}
