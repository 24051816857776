@import "./colors";
@import "./mixins";
@import "./typography";

/*================================
      DATATABLE
  ==================================*/
// Table Filter
.table-filters {
    @include set-flex(space-between, center, center);
    margin-bottom: 20px;

    .grouped-filter {
        display: inline-flex;
        align-items: center;
        border-radius: 6px;
        padding: 6px 10px;
        background-color: $off-white;
        select {
            border: 1px solid $gray-dark;
        }

        .filter-btn {
            margin-bottom: 0px;

            li {
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                line-height: 2;
                text-align: center;
                cursor: pointer;
                @include set-transition(all 0.3s);
                color: rgba(0, 0, 0, 0.54);
                fill: rgba(0, 0, 0, 0.54);
                background-color: transparent;

                &:hover {
                    background-color: $form-bg-color;
                }

                svg {
                    cursor: pointer;
                    width: 18px;
                    height: auto;
                }
                &.active-green{
                    svg{
                        path{
                            fill: $green-success;
                        }
                    }
                }
                &.Inactive-red{
                    svg{
                        path{
                            fill: $red-failure;
                        }
                    }
                }
            }
        }

        button,
        a {
            margin-right: 10px;
        }
    }
}



// Table
#table-content {
    & .rdt_TableHeadRow {
        background-color: $form-bg-color;
        min-height: 50px;
        border-bottom-color: transparent;
        border-radius: 5px;

        & .rdt_TableCol_Sortable {
            span {
                opacity: 1;
            }
        }
    }

    & .rdt_TableHeader {
        display: none;
    }

    .eHxVgR {
        border: 0px;
        background: $off-white;
        border-radius: 4px;
    }

    .rdt_TableHead {
        border-left: 5px solid #3766e8;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
    }

    .rdt_TableRow {
        border-left: 5px solid #3766e8;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-radius: 5px;
        margin: 7px 0;
        border-bottom: 0;
        padding: 10px 0;

        &:hover {
            background: $off-white;
        }
    }

    .rdt_TableCell {
        .filter-btn {
            margin-bottom: 0;

            li {
                position: relative;
                -webkit-user-select: none;
                -moz-user-select: none;
                -ms-user-select: none;
                user-select: none;
                border: none;
                border-radius: 50%;
                height: 30px;
                width: 30px;
                line-height: 2.1;
                text-align: center;
                cursor: pointer;
                @include set-transition(all 0.3s);
                color: rgba(0, 0, 0, 0.54);
                fill: rgba(0, 0, 0, 0.54);
                background-color: transparent;

                &:hover {
                    background-color: $form-bg-color;
                }

                svg {
                    width: 15px;
                    height: auto;
                    cursor: pointer;
                }
            }
        }
    }

    .rdt_Pagination {
        border-top: 0px;

        .dQdisw {
            width: 30px;
            height: 30px;
            padding: 0;
            margin: 6px;
            text-align: center;
            border: 0px;
            background-color: $off-white;
            border-radius: 4px;
        }
    }

}
// Tabs filter
.filter-tabs{
    @include set-flex(flex-end, center, center); 
}

.filter-block{
    display: flex;
    align-items: center;
}

/* Screen Size - 592px */
@media (max-width: 592px) {
    .grouped-filter {
      form {
        justify-content: center;
      }

      button,
      a,
      .filter-btn {
        margin-bottom: 0px;
        margin-right: 10px;
      }

      select {
        width: 25%;
      }
    }
}

/* Screen Size - 425px */
@media (max-width: 425px) {
    .table-filters{
        .grouped-filter{
        justify-content: space-between;
        width: 100%;
            .filter-btn{
                margin-top: 0px;
                margin-right: 0px;
            }
        }
    }
    
  .table-filters {
      display: block;
    }
    .filter-btn{
     margin-top: 20px;
    }
}
    