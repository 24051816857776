@import "./colors";
@import "./fonts";
@import "./mixins";
@import "./typography";

// -----------------------------------------------------------------------------
// This file contains all Common Style
// -----------------------------------------------------------------------------

.btn {
    display: inline-block;
    margin-top: 0px;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 21px;
    align-items: center;
    text-align: center;
    border-radius: 5px;
    @include set-transition();

    &.focus,
    &:focus {
        outline: 0;
        box-shadow: 0 0 0 0.2rem transparent;
    }
}

.btn-primary {
    background: $primary-color;
    border: 1px solid $primary-color;
    color: $pure-white;

    &:hover {
        background: $primary-color-dark;
        border: 1px solid $primary-color-dark;
        color: $pure-white;
    }

    &.disabled,
    &:disabled {
        color: $pure-white;
        background-color: $btn-disabled-color;
        border-color: $btn-disabled-color;
        cursor: not-allowed;
    }
}

.btn-secondary {
    background: $pure-white;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
        background: $primary-color;
        border: 1px solid $primary-color;
        color: $pure-white;
    }
}

.btn-tertiary {
    background: $pure-white;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover,
    &:active,
    &:focus {
        background: $off-white;
        border: 1px solid $primary-color;
        color: $primary-color;
    }
}

.btn-primary-disabl,
.btn-primary-disable {
    background-color: $btn-disabled-color;
    border: 1px solid $btn-disabled-color;
    color: $pure-white;

    &:hover {
        background-color: $btn-disabled-color;
        border: 1px solid $btn-disabled-color;
        color: $pure-white;
    }
}

.btn-secondary-disabl,
.btn-secondary-disable {
    background-color: $btn-disabled-color;
    border: 1px solid $btn-disabled-color;
    color: $pure-white;

    &:hover {
        background-color: $btn-disabled-color;
        border: 1px solid $btn-disabled-color;
        color: $pure-white;
    }
}

.btn-tertiary-disabl,
.btn-tertiary-disable {
    background-color: $pure-white;
    border: 1px solid $primary-color;
    color: $primary-color;

    &:hover {
        background-color: $off-white;
        border: 1px solid $primary-color;
        color: $primary-color;
    }
}


.btn-xs {
    padding: 2px 25px;
    font-size: 12px;
}

.btn-sm {
    padding: 5px 15px;
}

.btn-md {
    padding: 8px 20px;
}

.btn-lg {
    padding: 16px 32px;
}

.btn-xl{
    padding: 10px 40px;
    svg {
        background-color: $primary-color-dark;
        border-radius: 50px;
        margin-right: 5px;
    }
}

.btn-single-icon{
    padding: 5px 10px;
    border-color: $text-tertiary;
    svg{
        path{
            fill: $text-tertiary;
        }
    }
}

.btn-icon-sm{
    svg{
        width: 14px;
        height: 14px;
        margin-right: 5px;
        path{
            fill:$pure-white;
        }
    }
}

.btn-icon{
    display: flex;
    align-items: center;
    svg{
        width: 12px;
        height: 12px;
        margin-right: 5px;
        path{
            fill:$pure-white;
        }
    }
}



.btn-secondary:not(:disabled):not(.disabled).active:focus, 
.btn-secondary:not(:disabled):not(.disabled):active:focus, 
.show>.btn-secondary.dropdown-toggle:focus,
.btn-primary:not(:disabled):not(.disabled).active:focus,
.btn-primary:not(:disabled):not(.disabled):active:focus,
.show>.btn-primary.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem transparent;
}

.btn-secondary:not(:disabled):not(.disabled).active, 
.btn-secondary:not(:disabled):not(.disabled):active{
    background-color: $primary-color;
    border-color: $primary-color;
    color: $pure-white;
}
.show>.btn-secondary.dropdown-toggle{
    background-color: $primary-color;
    border-color: $primary-color;
    color: $pure-white;
}

.curser-btn{
  cursor: pointer;
}



.filter-btn-expand{
    background-color: $primary-extra-light-focused;
}

.link{
    text-decoration: underline;
    color: $primary-color;
        &:hover{
            color: $primary-color-dark;
        }  
}

.block-group{
    display: flex;
    align-items: center;
    .btn{
        margin-right: 20px;
    }
}

.button-group{
    display: flex;
}

.table-filters.content-end,
.content-end{
        justify-content: flex-end;
}


@media (max-width: 575px) {
    .content-end{
        justify-content: flex-start;
    }
}

/* Screen Size - 375px */
@media (max-width: 375px) {
    .block-group{
        display: grid;
        text-align: center;
        .btn{
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}