@import "./colors";
@import "./mixins";
@import "./typography";

// -----------------------------------------------------------------------------
// This file contains all @typogrpahy declarations.
// -----------------------------------------------------------------------------

.form-control {
    font-size: 14px;
    font-weight: 400;
    line-height: 17.41px;
    border-radius: 6px;
    height: 37px;
    border: 1px solid $gray-dark;
    box-shadow: 0 0 0 1px transparent;
    &:focus {
        border-color: $primary-color;
        box-shadow: 0 0 0 1px $primary-color;
    }
}

label{
    font-size: 14px;
    font-weight: 500;
    line-height: 16.41px;
    color: $text-secondary;
    .small {
        font-size: 12px;
        font-weight: 400;
        line-height: 14.06px;
        color: $text-secondary;
    }
}

textarea.form-control {
    height: 72px;
}

.form-control:focus {
    label {
        color: $primary-color;
    }
}

input{
    &::placeholder{
        color: $off-white;
    }
}

.file-upload {
    position: relative;
    overflow: hidden;
    cursor: pointer;

    & input {
        position: absolute;
        font-size: 50px;
        opacity: 0;
        right: 0;
        top: 0;
        left: 0;
        width: 100%;
        height: auto;
        cursor: pointer;
    }
}

.action-select{
    width: 230px;
}
.comment-box{
    .form-group{
        textarea{
            background-color: $gray;
        }
    }
}

.search-filter {
    position: relative;
    width: 50%;
    float: right;

    input {
        border: 1px solid transparent;
        background: #f3f3f4;
    }

    & svg {
        position: absolute;
        right: 10px;
        top: 10px;
        width: 18px;
        opacity: 0.5;
    }
}



