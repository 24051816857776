@import "../../../Sass/colors";

.block-group{
    display: flex;
    align-items: center;
    button{
        margin-right: 20px;
    }
}
// Modal Checklist
.modal-checklist{
    display: flex;
    flex: 2;
    flex-direction: row;
    height: 100%;
    .modal-container{
        .modal-main{
            height: 100%;
        }
    }
}
.modal-sidebar{
    background-color: $pure-white;
    padding: 25px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    .select-block{
        margin-top: 30px;
        height: 600px;
        ul{
            display: block;
            height: 800px;
            li{
align-items: center;
    color: #545454;
    cursor: pointer;
    display: flex;
    padding: 8px 16px;
    border-radius: 4px;
    -webkit-user-select: none;
    user-select: none;
    width: 100%;
    margin-bottom: 10px;
    max-width: 185px;
                &:hover{
                    color: $primary-color;
                }
                &.active{
                background-color: $primary-color;
                border-radius: 4px;
                color: $pure-white;
                font-weight: 500;
                }
                &:last-child{
                    margin-bottom: 0px;
                }
            }
            
        }
    }
}

.modal-container{
    display: flex;
    flex: 1;
    flex-direction: column;
    .modal-main{
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        justify-content: flex-start;

        .option-block{
                box-shadow: 0 2px 5px #dadada;
                margin: 15px;
                cursor: pointer;
                display: flex;
                flex-direction: column;
                height: auto;
                width: 304px;
                border-radius: 8px;
                float: left;
            img{
                border-top-left-radius: 8px;
                border-top-right-radius: 8px;
                height: 150px;
                width: 100%;
                -o-object-fit: cover;
                object-fit: cover;
            }
            .main-content{
                padding: 16px;
                background-color: $pure-white;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                p{
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 2;
                color: $text-primary;
                display: -webkit-box;
                margin-bottom: 0;
                overflow: hidden;
                text-overflow: ellipsis;
                }
            }
        }
    }
}
.checklist-modal{
    .modal-content{
        padding: 0px;
        height: 100%;
    }
}

// Welcome Pop
.welcome-pop{
    .content{
        padding: 30px;
        color: $text-primary;
    }
    .action-block{
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .block{
            padding: 0px 80px;
        }
    }
}

.modal-dialog.checklist-modal {
    height: 100%;
}

@media (min-width: 768px){
    .modal-dialog.checklist-modal {
        height: calc(100vh - 64px);
        margin: 32px auto;
        min-height: 600px;
    }
}

@media (min-width: 768px){
    .modal-dialog.checklist-modalg {
        width: 659px;
    }
        .checklist-modal{
            .modal-content{
                    height: 100%;
            .checklist-modal{
                .modal-content{
                    .modal-body {
                        flex-direction: row;
                        }
                        .modal-container{
                            .modal-main{
                                padding: 0px;
                                .option-block{
                                    width: 350px;
                                    margin: 15px 0px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }

@media (max-width: 991px){
    .modal-checklist{
        flex-direction: column;
    }
    .modal-sidebar{
        .select-block{
            margin-top: 20px;
            height: auto;
            ul{
                display: none;
            }
            .mobile-select{
                display: block;
            }
        }
    }
    .modal-container {
        border-top-left-radius: 0px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 10px;
        .modal-main{
            .option-block{
                float: none;
                margin: 0 auto 20px;
            }
        }
    }
    .welcome-pop{
        .action-block{
            .block{
                padding: 0px 15px;
            }
        }
    }
}

@media (max-width: 768px){
.modal-body {
    flex-direction: row;
    }
    .modal-container{
        .modal-main{
            padding: 0px;
        }
    }
}

/* Screen Size - 375px */
@media (max-width: 375px) {
    .welcome-pop .content {
        padding: 5px;
    }
    .block-group{
        display: grid;
        text-align: center;
        button{
            margin-right: 0;
            margin-bottom: 20px;
        }
    }
}