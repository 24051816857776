// Color Variables Import
@import "../../../../Sass/colors";

@import "../../../../Sass/mixins";

/*================================
      TOP HEADER
  ==================================*/
.header {
  padding: 10px 30px;
  background-color: $primary-extra-light;

  & .dropdown {
    @include set-flex(unset, center, center);

    .dropdown-toggle {
      @include set-flex(unset, center, unset);
    }

    .dropdown-menu {
      border-radius: 7px;
      display: block;
      border: 0px;
      transition: 0.3s;
      visibility: hidden;
      top: 0;
      opacity: 0;
      box-shadow: 0 1px 24px rgba(0, 0, 0, 0.1);
      background-color: $pure-white;
      transform: translate3d(-50px, 40px, 0px) !important;

      a {
        svg {
          width: 15px;
          height: auto;
          margin-right: 5px;
        }
      }
    }

    .dropdown-item:active {
      color: $pure-white;
      text-decoration: none;
      background-color: transparent;
    }

    .dropdown-menu.show {
      display: block;
      min-width: 160px;
      visibility: visible;
      opacity: 1;
    }

    .lh15 {
      line-height: 1.5;
    }
  }

  .dropdown-menu {
    border-radius: 4px;
  }

  .user-setting {
    @include set-flex(unset, center, unset);

    & label {
      margin-right: 15px;
      margin-bottom: 0px;
    }
  }

  // .notification{
  //   span{
  //     margin-right: 40px;
  //   }
  // }

  .user-thumb {
    width: 30px;
    height: 30px;
    border-radius: 50px;
    line-height: 2.6;
  }
}

@media (max-width: 991px) {

  .header {

    & .dropdown {

      .dropdown-menu {
        transform: translate3d(-99px, 40px, 0px) !important;
      }
    }
  }
}