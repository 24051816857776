@import "./colors";
@import "./mixins";

/*================================
      MODAL
  ==================================*/
.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    background-color: $pure-white;
    background-clip: padding-box;
    border: 0px;
    border-radius: .3rem;
    outline: 0;
    border-radius: 16px;
    padding: 30px;

    & .modal-header {
        border-bottom: 0px;
        padding: 0px;
        margin-bottom: 30px;

        p {
            margin-bottom: 0;
        }

        .close {
            border: 0px;
            outline: 0;
            background: $form-bg-color;
            border-radius: 50px;
            padding: 0px;
            width: 30px;
            height: 30px;

            span {
                color: $primary-color-dark;
            }
        }
        .modal-title{
            max-width: 400px;
            word-break: break-all;
        }
    }

    & .modal-body {
        padding: 0px;
        margin-bottom: 30px;
        .close {
            border: 0px;
            outline: 0;
            background: $form-bg-color;
            border-radius: 50px;
            padding: 0px;
            width: 30px;
            height: 30px;

            span {
                color: $primary-color-dark;
            }
        }
    }

    & .modal-footer {
        flex-wrap: wrap;
        @include set-flex(flex-end, center, unset);
        padding: 0px;
        border: 0px;

        & .align-right {
            margin: 0 0 0 auto;
        }
    }
}

.form-popup {
    .modal-content {
        padding: 30px;
    }
}

.modal-90w{
    max-width: 90%;
}

@media (min-width: 1420px){
.modal-dialog.checklist-modal {
    max-width: 1339px;
    }
}

@media (min-width: 992px){
.modal-dialog.checklist-modal {
    max-width: 1012px;
    }
}