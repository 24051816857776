@import "../../../../Sass/mixins";
@import "../../../../Sass/colors";

#sidebar-fixed {
  bottom: 0px;
  left: 0px;
  position: fixed;
  top: 0;
  z-index: 200;
  @include set-transition(all 0.5s);
  box-shadow: rgba(0, 0, 0, 0.15) 0 0px 12px;


    #sidebar-nav {
      width: 235px;
      height: 100%;
      position: relative;
      @include set-transition(all 0.5s);

      &.active {
        margin-left: -235px;
        @include set-transition(all 0.5s);
      }
    }
  }

@media (max-width: 991px) {
  #sidebar-fixed {
    width: 0px;
    display: none;

    .sidebar-block {
      #sidebar-nav {
        &.active {
          margin-left: 0;
        }
      }
    }
  }

  #sidebar-fixed.active {
    width: auto;
  }

  .overlay {
    position: fixed;
    z-index: 20;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.3);
    opacity: 1;
    transition: opacity 0.3s ease 0s;
    display: block;
  }
}

/* Screen Size - 425px */
@media (max-width: 425px) {
  #sidebar-fixed .sidebar-block .sidebar-global{
    width: 60px;
  }
#sidebar-fixed .sidebar-block #sidebar-nav .sidebar-nav-link{
    min-width: 240px;
  }
}

