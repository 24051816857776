// -----------------------------------------------------------------------------
// This file contains all @mixins declarations, if any.
// -----------------------------------------------------------------------------
@mixin set-flex ( $justify-content: space-between, $align-items: center, $align-self: center) {
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;

    justify-content: $justify-content;

    -webkit-box-align: $align-items;
    -moz-box-align: $align-items;
    -ms-flex-align: $align-items;
    -webkit-align-items: $align-items;
    align-items: $align-items;

    -webkit-box-align: $align-self;
    -moz-box-align: $align-self;
    -ms-flex-align: $align-self;
    -webkit-align-self: $align-self;
    align-self: $align-self;
}

@mixin set-transition ($transition: all 0.5s) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}


@mixin set-position ($position, $top, $right, $bottom, $left) {
    position: $position;
    top: $top;
    right: $right;
    bottom: $bottom;
    left: $left;
}